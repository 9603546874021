import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M5110 9430 c-285 -28 -626 -115 -918 -233 -119 -47 -127 -55 -211
-225 -60 -118 -91 -200 -130 -336 -67 -230 -89 -396 -54 -396 19 0 77 -41 112
-79 31 -34 48 -76 57 -140 4 -34 13 -55 24 -61 10 -5 73 -21 141 -34 68 -14
159 -34 202 -46 42 -11 112 -29 155 -40 74 -20 122 -20 122 0 0 18 76 80 129
106 100 47 141 57 212 51 37 -3 82 -12 102 -21 35 -14 37 -14 74 13 21 16 80
69 131 119 90 87 92 91 92 136 0 26 9 64 22 90 l21 44 -46 119 c-64 163 -83
224 -102 328 -24 124 -16 313 16 405 21 60 86 171 109 185 5 3 10 10 10 16 0
10 -158 10 -270 -1z"/>
<path d="M5470 9403 c-109 -185 -104 -465 13 -758 21 -55 50 -114 63 -131 23
-31 26 -32 91 -30 l69 2 159 105 c400 264 683 387 1053 455 13 3 22 10 19 17
-2 6 -64 39 -138 73 -268 123 -648 231 -939 268 -69 9 -159 21 -200 26 -41 6
-96 10 -122 10 -43 0 -48 -3 -68 -37z"/>
<path d="M3615 8877 c-82 -56 -135 -95 -189 -137 -28 -22 -158 -133 -176 -150
-102 -96 -135 -128 -195 -191 -141 -149 -208 -230 -285 -344 -18 -27 -36 -52
-39 -55 -4 -3 -19 -25 -35 -49 l-28 -44 35 7 c20 4 62 15 94 26 81 26 206 47
353 60 205 17 210 18 220 46 14 35 73 113 86 114 25 0 46 56 74 190 45 217 80
333 149 489 22 52 41 96 41 98 0 10 -24 -4 -105 -60z"/>
<path d="M7190 8814 c-227 -20 -501 -104 -759 -234 -162 -81 -404 -233 -484
-304 -34 -31 -38 -38 -32 -68 5 -28 0 -43 -31 -88 -20 -30 -41 -57 -45 -60 -9
-6 40 -79 101 -152 59 -70 360 -372 430 -431 30 -25 96 -78 145 -118 50 -39
99 -78 111 -87 11 -10 27 -21 36 -27 33 -22 75 -51 113 -80 45 -34 250 -159
360 -220 317 -177 672 -299 972 -336 132 -17 157 -9 161 46 1 22 10 71 20 110
20 81 71 144 120 146 83 3 85 3 103 40 11 19 26 72 35 116 l17 81 -31 84 c-17
45 -43 114 -57 152 -33 87 -146 322 -197 405 -21 34 -38 65 -38 68 0 15 -243
360 -269 383 -3 3 -28 32 -56 65 -116 141 -322 336 -481 457 -56 43 -67 47
-130 51 -38 3 -89 3 -114 1z"/>
<path d="M5598 7992 c-2 -4 -20 -19 -41 -33 -38 -27 -173 -154 -226 -214 -28
-32 -29 -38 -27 -113 2 -57 7 -85 19 -96 8 -9 18 -16 20 -16 3 0 70 -31 150
-70 80 -38 147 -70 150 -70 3 0 69 -36 148 -80 78 -44 144 -80 145 -80 9 0
310 -190 394 -248 200 -139 426 -300 442 -315 7 -7 33 -28 58 -47 25 -19 47
-37 50 -40 3 -3 45 -39 94 -80 50 -41 99 -84 111 -95 11 -11 60 -56 109 -100
48 -44 131 -125 185 -179 173 -178 187 -189 197 -161 3 8 12 15 19 15 7 0 15
7 19 15 3 8 14 15 26 15 11 0 40 5 64 12 57 15 119 -3 157 -46 36 -40 65 -31
126 41 141 167 303 409 303 453 0 4 -73 10 -162 13 -412 15 -851 168 -1333
466 -110 67 -265 175 -345 238 -244 193 -438 374 -602 563 -60 69 -132 151
-159 183 -51 57 -84 83 -91 69z"/>
<path d="M3055 7795 c-139 -19 -199 -33 -338 -81 -186 -63 -209 -78 -253 -172
-146 -312 -253 -708 -284 -1052 -20 -208 -4 -623 29 -795 6 -27 15 -79 21
-115 35 -196 137 -545 190 -650 10 -19 23 -55 30 -80 12 -43 83 -189 156 -320
54 -96 216 -328 288 -412 59 -69 67 -75 152 -106 49 -18 122 -41 163 -52 l73
-19 -7 47 c-4 26 -10 81 -14 122 -4 41 -8 64 -9 50 -1 -14 -11 -31 -22 -38
-17 -11 -20 -9 -26 14 -6 22 -10 24 -38 19 -19 -4 -26 -3 -18 3 19 13 3 42
-22 42 -26 0 -66 34 -66 56 0 8 -5 12 -10 9 -5 -3 -10 -1 -10 6 0 7 -3 9 -7 6
-12 -13 -31 28 -26 57 4 18 1 31 -8 37 -12 8 -12 11 1 19 12 8 9 10 -12 10
l-28 0 0 120 0 120 -25 0 c-22 0 -25 4 -25 40 0 38 -2 40 -30 40 -28 0 -30 2
-30 40 0 29 4 40 15 40 11 0 15 11 15 40 0 36 -2 40 -25 40 -25 0 -25 1 -25
80 0 64 -3 80 -15 80 -11 0 -15 11 -15 40 0 29 4 40 15 40 13 0 15 20 15 120
0 73 4 120 10 120 6 0 10 33 10 80 0 64 3 80 15 80 11 0 15 11 15 40 l0 40 40
0 40 0 0 80 c0 79 0 80 25 80 23 0 25 4 25 40 0 29 4 40 15 40 11 0 15 11 15
40 l0 40 40 0 40 0 0 80 0 80 40 0 40 0 0 40 0 40 40 0 c27 0 40 -4 40 -13 0
-8 9 -22 20 -32 19 -18 20 -17 31 13 6 18 15 32 20 32 5 0 9 18 9 40 0 22 4
40 10 40 5 0 12 11 16 25 4 15 16 28 30 31 19 5 24 13 24 40 0 28 4 34 20 34
11 0 23 7 26 15 4 8 12 15 18 15 7 0 23 11 36 25 13 14 32 25 42 25 22 0 22
-9 -2 86 -11 44 -29 147 -40 229 -11 83 -27 197 -35 255 -8 58 -19 197 -25
310 -17 340 -15 325 -64 360 -23 17 -50 47 -61 68 -11 20 -24 40 -30 44 -15
11 -168 7 -270 -7z"/>
<path d="M3827 7747 c-85 -54 -87 -58 -87 -179 0 -109 23 -392 40 -503 35
-220 44 -274 67 -390 35 -178 51 -206 99 -170 10 8 25 15 32 15 7 0 19 6 26
13 14 14 76 115 76 123 0 3 9 19 21 35 11 16 16 29 10 29 -5 0 0 11 12 24 35
39 48 56 42 56 -4 0 5 14 20 31 14 18 24 38 21 45 -3 8 3 18 13 23 10 6 35 36
57 68 21 32 41 60 45 63 4 4 45 59 71 95 4 5 36 46 70 90 90 113 111 150 102
177 -4 13 -11 26 -16 29 -6 4 -18 48 -29 99 -10 51 -22 97 -26 101 -10 10
-118 45 -196 63 -376 91 -416 96 -470 63z"/>
<path d="M5173 7328 c-15 -16 -41 -36 -58 -45 -96 -51 -133 -66 -157 -65 -16
0 -28 -3 -28 -8 0 -4 -13 -10 -29 -12 -23 -3 -28 -1 -23 11 11 32 -63 -47
-139 -149 -43 -58 -83 -110 -87 -115 -17 -20 -229 -340 -272 -410 -24 -38 -56
-97 -72 -130 l-30 -59 21 -17 c12 -10 21 -20 21 -24 0 -3 12 -22 26 -41 21
-26 31 -33 49 -28 17 4 25 1 29 -14 4 -11 13 -26 21 -32 12 -10 17 -7 27 16 7
16 15 31 19 34 17 13 38 69 29 75 -17 10 0 26 21 20 15 -5 19 -1 19 19 0 14 8
31 19 38 10 7 26 28 35 46 9 18 21 30 26 27 11 -7 104 84 96 93 -10 10 56 71
70 65 8 -3 14 0 14 6 0 6 -4 11 -10 11 -23 0 -7 18 30 35 22 10 40 23 40 29 0
6 18 20 40 30 22 11 40 23 40 27 0 4 9 11 20 14 11 3 20 13 20 20 0 8 15 18
33 24 60 18 122 43 127 50 9 13 40 21 40 11 0 -6 33 -10 75 -10 56 0 86 -5
115 -20 22 -11 59 -20 85 -20 29 0 45 -4 45 -12 0 -15 60 -58 81 -58 8 0 29
-9 47 -20 18 -11 48 -20 67 -20 27 0 44 -9 75 -40 22 -22 45 -40 51 -40 6 0
22 -9 36 -20 14 -11 33 -20 42 -20 9 -1 39 -14 66 -30 28 -16 71 -41 97 -54
25 -14 54 -36 65 -48 10 -13 35 -33 56 -45 l37 -22 0 24 c0 18 4 23 16 18 8
-3 13 -12 10 -19 -7 -18 58 -80 100 -96 18 -6 34 -18 34 -25 0 -15 47 -63 62
-63 6 0 34 -24 62 -53 29 -29 66 -59 84 -66 18 -7 32 -20 32 -27 0 -8 6 -14
13 -14 6 -1 26 -19 42 -40 17 -21 35 -39 40 -40 6 -1 24 -19 40 -40 17 -21 33
-39 38 -40 11 0 66 -63 67 -77 0 -6 18 -30 40 -52 22 -22 40 -45 40 -50 0 -12
48 -61 60 -61 4 0 19 -17 31 -37 13 -21 27 -39 31 -41 4 -2 8 -21 8 -42 0 -24
5 -40 15 -44 8 -3 15 -11 15 -18 0 -7 9 -19 20 -26 14 -9 19 -19 15 -35 -3
-12 -7 -49 -9 -82 -2 -40 -8 -59 -16 -57 -8 2 -9 -5 -5 -19 5 -16 4 -19 -5
-14 -8 5 -11 4 -6 -3 6 -11 -70 -92 -86 -92 -5 0 -27 -18 -49 -40 -22 -22 -44
-40 -47 -40 -4 0 -18 -10 -32 -23 -14 -13 -47 -38 -74 -57 -27 -19 -77 -55
-112 -80 -147 -104 -310 -211 -346 -227 -16 -7 -28 -17 -28 -23 0 -5 -6 -10
-12 -10 -16 -1 -147 -80 -133 -80 6 0 26 10 45 22 19 11 62 34 94 51 33 16 66
36 74 43 7 8 20 14 28 14 8 0 14 5 14 10 0 6 5 10 10 10 12 0 342 203 362 223
7 7 42 32 76 55 93 63 342 251 370 279 8 7 30 26 50 41 118 88 124 99 125 270
l2 137 -30 35 c-113 130 -262 280 -395 400 -46 41 -100 90 -119 108 -18 18
-42 38 -51 45 -18 13 -105 85 -124 102 -60 56 -351 267 -538 390 -303 199
-815 481 -943 520 -42 12 -44 12 -72 -17z"/>
<path d="M8656 6404 c-8 -37 -74 -127 -86 -119 -5 3 -28 1 -51 -5 -30 -8 -44
-8 -54 0 -20 16 -52 -8 -100 -76 -102 -145 -185 -256 -247 -332 -72 -88 -86
-118 -70 -149 17 -31 35 -168 28 -214 -3 -24 -9 -66 -12 -93 -3 -27 -13 -60
-21 -73 -22 -33 -11 -77 53 -203 30 -60 54 -112 54 -115 0 -4 7 -21 15 -38 35
-77 77 -208 90 -281 8 -43 18 -83 23 -88 32 -32 239 464 306 732 51 204 83
413 100 650 14 196 8 380 -12 416 -8 15 -11 13 -16 -12z"/>
<path d="M3290 6049 c0 -27 2 -29 15 -15 8 8 15 22 15 30 0 9 -7 16 -15 16
-10 0 -15 -10 -15 -31z"/>
<path d="M4276 5546 c-25 -21 -31 -22 -43 -10 -20 20 -43 17 -43 -4 0 -86 271
-662 468 -994 79 -134 126 -197 137 -186 6 6 -112 224 -140 258 -14 16 -193
370 -244 480 -46 100 -110 275 -117 318 -4 24 -1 48 6 63 15 26 23 99 12 99
-4 -1 -20 -11 -36 -24z"/>
<path d="M3854 5442 c-51 -36 -202 -581 -255 -922 -25 -165 -39 -573 -20 -608
11 -22 16 -22 214 -22 228 0 581 35 610 60 10 8 -37 6 -250 -13 -94 -9 -184
-14 -200 -11 -15 2 -52 9 -82 14 -30 6 -57 10 -60 10 -3 0 3 6 14 14 17 12 17
13 -9 20 -15 4 -24 11 -21 17 4 5 -1 21 -10 36 -12 17 -18 52 -21 111 -2 47
-7 88 -12 91 -21 14 -22 148 -1 136 4 -3 7 27 5 67 -1 40 -1 65 2 56 2 -10 9
-18 17 -18 8 0 11 7 8 20 -3 11 -9 20 -14 20 -5 0 -9 15 -9 33 0 39 34 173 48
190 6 7 10 38 11 70 0 53 18 135 31 147 3 3 13 34 23 70 43 156 41 150 57 140
20 -13 32 -12 52 3 15 12 14 21 -17 116 -49 147 -73 180 -111 153z"/>
<path d="M7485 5228 c-11 -6 -22 -14 -25 -17 -3 -3 -21 -18 -40 -32 -19 -15
-48 -39 -65 -54 -16 -15 -82 -66 -145 -113 -121 -92 -132 -100 -200 -146 -24
-15 -53 -37 -64 -47 -11 -11 -24 -19 -29 -19 -4 0 -14 -6 -22 -12 -7 -7 -39
-30 -71 -50 -33 -21 -68 -44 -79 -50 -177 -114 -333 -205 -523 -306 -84 -45
-154 -82 -156 -82 -2 0 -71 -32 -152 -71 -82 -39 -170 -79 -195 -89 -26 -10
-66 -28 -90 -38 -24 -11 -94 -40 -156 -63 -62 -24 -120 -49 -129 -56 -14 -10
-14 -13 4 -31 20 -20 20 -22 4 -86 -16 -63 -51 -117 -82 -128 -27 -9 11 -67
106 -162 74 -74 271 -250 319 -284 6 -4 29 -22 51 -40 23 -17 45 -32 49 -32 4
0 28 -14 53 -32 26 -17 58 -39 72 -48 44 -29 220 -111 267 -125 43 -13 50 -13
77 4 17 10 83 35 146 54 206 62 444 164 608 259 46 26 85 48 88 48 10 0 139
84 191 124 32 26 110 85 173 133 112 85 365 327 447 428 210 257 213 262 213
319 0 93 -65 348 -119 471 -24 54 -41 94 -57 135 -20 51 -61 121 -78 132 -6 4
-53 5 -103 1 -67 -4 -93 -3 -93 6 0 6 -13 11 -28 11 -29 0 -52 14 -107 68 -38
36 -34 35 -60 20z m-45 -548 c0 -29 -4 -40 -15 -40 -12 0 -15 -20 -15 -120 0
-73 -4 -120 -10 -120 -6 0 -10 -33 -10 -80 0 -64 -3 -80 -15 -80 -11 0 -15
-11 -15 -40 l0 -40 -40 0 -40 0 0 -40 c0 -29 -4 -40 -15 -40 -11 0 -15 -11
-15 -40 0 -29 4 -40 15 -40 12 0 15 -16 15 -80 0 -79 0 -80 -25 -80 -22 0 -25
-4 -25 -40 0 -29 -4 -40 -15 -40 -8 0 -15 -6 -16 -12 0 -10 -2 -10 -6 0 -2 6
-20 12 -39 12 -33 0 -34 -1 -34 -40 0 -29 -4 -40 -15 -40 -10 0 -15 -11 -15
-35 0 -33 1 -34 26 -25 33 13 34 13 34 -5 0 -8 -7 -15 -15 -15 -8 0 -12 -6 -9
-14 4 -11 -1 -13 -29 -6 -19 4 -41 11 -48 15 -8 5 -11 5 -7 0 4 -4 -4 -16 -17
-27 -14 -11 -25 -26 -25 -34 0 -9 -11 -14 -35 -14 -24 0 -35 -5 -35 -14 0 -8
-3 -21 -6 -29 -3 -8 2 -22 11 -31 15 -15 15 -16 -2 -16 -21 -1 -54 -24 -52
-36 4 -22 -2 -34 -17 -34 -9 0 -30 -8 -46 -17 -23 -14 -32 -15 -35 -5 -5 14
-33 17 -33 3 0 -5 6 -11 13 -14 6 -3 -6 -12 -28 -21 -22 -9 -60 -26 -85 -38
-25 -11 -54 -24 -65 -28 -11 -4 -36 -14 -56 -23 -20 -9 -47 -13 -60 -10 -14 4
-20 2 -16 -4 4 -6 -17 -18 -51 -28 -31 -10 -63 -20 -71 -23 -10 -4 -13 -1 -8
12 5 13 3 17 -11 14 -9 -2 -16 -9 -15 -15 1 -6 -11 -16 -27 -22 -17 -5 -30 -8
-30 -6 0 2 -3 10 -6 18 -3 8 2 22 11 31 15 15 13 16 -26 16 -40 0 -41 -1 -25
-19 16 -17 15 -19 -21 -24 -21 -4 -42 -2 -47 3 -7 7 -2 10 12 10 18 0 22 6 22
35 0 19 -5 35 -11 35 -6 0 -8 -9 -4 -20 8 -24 -14 -26 -97 -9 -115 23 -129 29
-123 49 5 16 0 18 -41 18 -26 0 -50 6 -54 12 -7 12 -37 31 -76 49 -11 5 -28
21 -38 36 -10 15 -23 24 -31 22 -7 -3 -23 5 -36 19 -12 13 -28 24 -36 24 -7 0
-13 6 -13 13 0 6 -16 23 -36 37 -55 39 -144 128 -144 144 0 8 5 16 11 18 6 2
11 44 13 105 1 62 7 107 15 117 13 16 16 41 7 71 -5 15 2 26 31 45 59 39 74
43 89 23 7 -10 14 -13 14 -6 0 6 -5 15 -10 18 -15 9 -2 25 20 25 11 0 49 16
85 35 36 19 65 32 65 29 0 -3 26 8 58 25 68 35 133 64 185 80 20 7 37 16 37
20 0 4 11 13 25 19 20 9 23 9 18 -3 -3 -9 1 -15 10 -15 19 0 31 17 16 22 -6 2
-1 12 10 22 19 17 21 17 21 3 0 -10 5 -17 10 -17 6 0 10 6 10 14 0 8 15 24 34
35 37 23 51 27 40 10 -4 -8 -1 -8 13 -1 12 7 25 7 34 1 21 -13 29 -11 23 6 -4
8 -12 15 -20 15 -19 0 -18 17 3 24 16 6 33 -8 33 -28 0 -5 11 1 25 14 14 13
24 29 22 34 -5 14 22 41 31 32 3 -4 0 -11 -8 -16 -18 -11 -12 -12 54 -10 27 1
55 8 63 16 8 8 23 14 34 14 10 0 19 5 19 11 0 6 18 10 43 9 47 -2 87 8 87 21
0 5 25 9 55 9 45 0 55 3 55 17 0 22 39 63 61 63 9 0 37 11 60 25 25 14 60 25
82 25 26 0 37 4 37 15 0 8 8 15 18 15 9 0 22 8 29 19 9 15 20 17 62 13 34 -3
51 -1 51 7 0 6 17 11 40 11 29 0 40 4 40 15 0 12 16 15 80 15 l80 0 0 -40z
m-905 -180 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
9 -10z m-58 -76 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m708
-834 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z
m-139 -85 c-8 -8 -17 -15 -20 -15 -3 0 -3 7 0 15 4 8 13 15 21 15 12 0 12 -2
-1 -15z m44 -10 c-7 -8 -16 -15 -21 -15 -5 0 -6 7 -3 15 4 8 13 15 21 15 13 0
13 -3 3 -15z m-100 -10 c0 -2 -7 -5 -15 -9 -9 -3 -15 0 -15 9 0 9 6 12 15 9 8
-4 15 -7 15 -9z m-3 -51 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-57 -28 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z"/>
<path d="M7047 3754 c-8 -8 3 -34 15 -34 9 0 43 29 36 31 -28 6 -46 7 -51 3z"/>
<path d="M6862 3468 c-16 -16 -15 -28 3 -28 8 0 15 9 15 20 0 23 -2 24 -18 8z"/>
<path d="M6830 3400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6480 3240 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6240 3160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4440 3743 c-325 -52 -506 -68 -682 -59 -136 7 -138 7 -138 -15 0
-27 25 -116 56 -201 l25 -67 102 -58 c56 -33 129 -70 162 -83 33 -13 87 -36
120 -50 133 -60 299 -124 363 -139 20 -5 64 -16 97 -25 117 -32 286 -67 417
-87 226 -35 846 -39 835 -6 -2 6 -10 14 -18 17 -8 4 -23 12 -34 18 -11 7 -27
15 -35 18 -64 26 -159 76 -177 93 -12 12 -25 21 -29 21 -3 0 -19 11 -36 25
-16 14 -36 25 -44 25 -9 0 -25 5 -36 11 -15 8 -17 13 -8 19 9 6 7 11 -10 20
-12 6 -24 9 -28 6 -3 -3 0 -6 6 -6 7 0 12 -15 12 -34 0 -31 3 -34 35 -40 19
-4 35 -11 35 -16 0 -6 9 -10 20 -10 17 0 20 -7 20 -41 0 -38 2 -40 28 -39 41
3 50 -1 22 -10 -36 -12 -225 -11 -365 0 -129 11 -199 22 -340 53 -49 10 -116
24 -147 31 -31 6 -60 14 -64 19 -4 4 -17 7 -29 7 -20 0 -176 49 -220 69 -11 5
-62 26 -114 46 -52 20 -126 53 -165 74 -64 35 -72 42 -77 76 -5 31 -1 42 25
75 29 36 35 39 84 39 39 1 52 5 52 16 0 11 11 15 40 15 36 0 40 3 40 25 0 23
4 25 40 25 29 0 40 4 40 15 0 11 11 15 40 15 33 0 40 -3 40 -20 0 -22 34 -28
46 -9 4 7 9 7 13 1 10 -15 91 -53 101 -47 8 5 59 -13 70 -25 5 -5 11 -6 60 -8
18 -1 30 -8 30 -15 0 -12 64 -35 107 -39 7 -1 14 -7 17 -15 3 -7 16 -13 29
-13 13 0 37 -6 53 -14 16 -8 68 -17 117 -21 48 -4 87 -11 87 -16 0 -5 9 -9 21
-9 11 0 19 4 17 9 -5 15 -88 112 -126 147 -33 32 -34 32 -150 33 -120 0 -165
8 -203 34 -12 9 -27 13 -34 11 -6 -3 -24 11 -39 31 -15 19 -34 35 -42 35 -7 0
-14 6 -14 14 0 8 -12 17 -27 21 -16 4 -22 10 -15 12 6 3 12 9 12 14 0 9 -42
10 -90 2z m-127 -60 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m-329 -282 c3 -4 3 -11 0 -14 -8 -8 -34 3 -34 14 0 11 27 12 34 0z"/>
<path d="M3298 3708 c23 -23 39 -20 29 7 -4 8 -17 15 -29 15 l-22 0 22 -22z"/>
<path d="M5176 3361 c-3 -5 1 -14 9 -21 13 -10 15 -9 15 9 0 21 -14 28 -24 12z"/>
<path d="M5277 3269 c4 -13 9 -17 15 -11 11 11 3 32 -12 32 -4 0 -6 -9 -3 -21z"/>
<path d="M6525 2755 c-131 -21 -216 -61 -277 -128 -63 -69 -93 -185 -68 -263
34 -106 111 -149 340 -193 183 -35 210 -44 210 -70 0 -46 -62 -71 -181 -71
-72 0 -133 12 -153 31 -5 5 -12 28 -15 51 l-6 43 -139 3 c-85 1 -143 -1 -150
-8 -18 -18 -10 -132 12 -175 70 -136 268 -202 525 -175 213 22 345 89 402 205
24 49 33 182 15 225 -32 77 -130 128 -310 161 -179 32 -210 40 -225 55 -11 12
-12 20 -5 35 20 36 63 49 162 49 113 0 158 -20 158 -70 0 -19 7 -33 19 -40 28
-14 244 -13 259 2 20 20 15 123 -9 173 -65 139 -294 204 -564 160z m-65 -70
c-33 -19 -19 -19 25 -1 19 8 35 13 35 11 0 -17 -16 -35 -31 -35 -16 0 -18 -4
-10 -18 17 -32 16 -98 -2 -116 -9 -10 -17 -24 -17 -32 0 -8 -4 -13 -9 -9 -6 3
-3 -15 5 -40 10 -26 20 -42 26 -38 7 4 8 1 3 -7 -4 -7 -2 -16 6 -21 12 -8 12
-10 0 -18 -8 -5 -11 -16 -8 -25 4 -10 1 -16 -8 -16 -8 0 -14 -8 -13 -17 1 -10
0 -31 0 -47 -2 -28 -3 -28 -49 -21 -94 14 -163 60 -193 128 -32 71 -8 173 55
240 49 51 133 95 185 96 l25 0 -25 -14z m40 -449 c0 -2 -7 -7 -16 -10 -8 -3
-12 -2 -9 4 6 10 25 14 25 6z m-176 -112 c9 -3 16 -16 16 -28 0 -32 26 -64 72
-87 l40 -21 -7 -66 c-4 -37 -9 -70 -12 -74 -8 -14 -45 -9 -127 17 -131 40
-195 111 -196 218 l0 47 99 0 c55 0 106 -3 115 -6z"/>
<path d="M1458 2683 c-24 -38 -157 -237 -296 -444 -139 -207 -252 -385 -252
-397 0 -11 7 -23 16 -26 32 -12 283 -7 294 7 7 6 34 47 61 90 l49 77 207 -2
206 -3 16 -78 c9 -44 23 -82 31 -87 24 -16 263 -12 285 3 17 13 13 34 -90 453
-59 241 -111 447 -116 457 -9 15 -28 17 -188 17 l-178 0 -45 -67z m210 -324
c12 -67 21 -123 19 -126 -3 -2 -50 -2 -105 -1 l-102 3 78 122 c42 68 79 123
82 123 3 0 15 -55 28 -121z"/>
<path d="M2550 2728 c-5 -13 -36 -153 -70 -313 -33 -159 -75 -354 -92 -433
-24 -114 -28 -145 -18 -157 10 -12 39 -15 141 -15 134 0 159 8 159 48 0 10 18
107 39 217 22 110 42 219 46 243 4 23 10 42 14 42 4 0 17 -37 29 -82 71 -268
85 -314 94 -325 7 -9 49 -13 143 -13 113 0 134 2 147 18 8 9 63 89 123 177 59
88 117 171 128 185 l20 25 -7 -40 c-8 -42 -79 -351 -97 -422 -18 -68 -9 -73
140 -73 69 0 132 4 139 8 14 9 25 53 71 272 17 80 53 254 82 388 42 198 49
245 39 257 -11 13 -42 15 -199 13 l-186 -3 -167 -230 c-92 -126 -171 -231
-176 -233 -5 -1 -21 39 -37 90 -15 51 -38 127 -51 168 -13 41 -32 104 -42 140
l-18 65 -192 3 c-192 2 -192 2 -202 -20z"/>
<path d="M4099 2734 c-12 -14 -7 -29 34 -108 27 -50 94 -179 150 -286 l101
-195 -32 -154 c-23 -114 -29 -158 -21 -168 9 -10 47 -13 150 -13 125 0 139 2
148 19 6 10 24 83 41 161 l30 143 52 56 c29 31 129 137 223 236 276 290 270
284 258 303 -9 15 -33 17 -170 20 l-160 2 -154 -175 c-84 -96 -156 -175 -160
-175 -5 0 -26 42 -123 253 l-45 97 -154 0 c-130 0 -157 -3 -168 -16z m1079
-33 c-1 -5 -79 -89 -173 -187 -93 -98 -208 -218 -255 -266 -87 -92 -108 -134
-110 -215 0 -17 -4 -35 -10 -38 -5 -3 -11 -18 -11 -33 -1 -23 -3 -22 -9 8 -6
33 -7 33 -12 10 -8 -33 -6 -70 4 -64 4 3 8 -1 7 -8 -1 -19 -17 -55 -18 -40 -1
8 -25 13 -78 14 l-78 2 73 3 c39 2 72 8 72 13 0 6 -4 10 -10 10 -5 0 -10 5
-10 11 0 5 5 7 10 4 6 -3 10 1 10 9 0 8 -4 18 -10 21 -6 4 -10 63 -10 146 0
120 2 139 16 139 12 0 15 10 13 45 -3 46 23 91 44 78 6 -3 7 -1 3 5 -4 6 10
30 30 52 28 31 39 38 47 28 6 -9 7 -7 3 6 -4 11 -3 22 1 25 4 3 23 24 42 46
19 22 63 73 99 113 l65 72 129 0 c72 0 128 -4 126 -9z m-743 -81 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m22 -47 c3 -7 -4 -9
-16 -6 -12 3 -18 9 -15 15 7 11 26 6 31 -9z m30 -75 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m43 -86 c0 -8 9 -25 20 -39 25 -33 26 -49 1 -26
-11 10 -22 30 -26 46 -4 15 -12 30 -17 33 -15 9 2 -69 20 -88 20 -23 0 -23
-21 0 -16 17 -20 68 -10 106 5 19 6 19 19 1 8 -10 14 -25 14 -33z m-180 -72
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m38
-86 c-4 -3 -12 5 -18 18 -12 22 -12 22 6 6 10 -10 15 -20 12 -24z m92 -63 c0
-32 -6 -24 -14 19 -4 25 -3 31 4 20 5 -8 10 -26 10 -39z m-30 14 c0 -8 -2 -15
-4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m-21 -29 c-3 -3
-11 5 -18 17 -13 21 -12 21 5 5 10 -10 16 -20 13 -22z m28 -38 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-30 -30 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-30 -150 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m-5 -43 c3 -13 1 -22 -3 -19 -9 5 -13 44 -5 44 2 0 6 -11 8 -25z
m151 -52 c-23 -2 -64 -2 -90 0 -26 2 -7 3 42 3 50 0 71 -1 48 -3z"/>
<path d="M4680 2415 c-7 -8 -8 -15 -2 -15 5 0 15 7 22 15 7 8 8 15 2 15 -5 0
-15 -7 -22 -15z"/>
<path d="M4615 2340 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4665 2200 c-10 -11 -16 -22 -13 -25 3 -3 13 4 23 15 10 11 16 22 13
25 -3 3 -13 -4 -23 -15z"/>
<path d="M4571 1984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7480 2728 c-5 -13 -27 -111 -50 -218 -22 -107 -48 -229 -56 -270
-25 -114 -15 -216 26 -281 62 -95 150 -140 312 -159 238 -27 459 38 581 169
90 98 105 141 187 530 23 106 40 205 38 220 l-3 26 -141 3 c-105 2 -144 -1
-151 -10 -6 -7 -25 -80 -43 -163 -92 -432 -89 -422 -136 -469 -48 -48 -99 -66
-192 -66 -167 0 -197 50 -151 255 71 318 90 427 78 441 -9 11 -42 14 -150 14
-135 0 -140 -1 -149 -22z"/>
<path d="M8890 2728 c-5 -13 -43 -185 -84 -383 -42 -198 -83 -393 -92 -433
-13 -58 -14 -75 -4 -87 11 -13 60 -15 319 -15 332 0 444 9 534 42 118 43 194
145 205 274 5 69 -17 126 -65 164 -22 16 -25 23 -13 27 8 3 34 25 57 49 98
101 95 263 -5 328 -76 50 -131 56 -501 56 l-341 0 -10 -22z m574 -208 c35 -13
35 -53 0 -85 -26 -24 -32 -25 -176 -25 l-150 0 7 43 c13 79 10 77 160 77 73 0
145 -4 159 -10z m-49 -339 c32 -7 42 -32 31 -76 -11 -44 -59 -55 -231 -55
-144 0 -156 1 -151 18 2 9 7 33 11 52 14 76 1 70 163 69 81 -1 161 -4 177 -8z"/>
<path d="M1931 1515 c-18 -86 -30 -162 -28 -170 5 -11 163 -14 1001 -14 937
-1 996 0 997 17 1 9 2 28 3 42 1 18 7 26 24 28 21 3 23 1 17 -20 -4 -13 -11
-27 -16 -33 -33 -35 -41 -34 1241 -34 1168 0 1250 1 1252 17 0 9 0 29 -1 45
-2 23 2 27 23 27 29 0 33 -15 11 -44 -8 -11 -15 -25 -15 -32 0 -10 270 -12
1302 -11 l1302 2 13 60 c6 33 21 102 33 154 11 52 20 101 20 108 0 17 -66 18
-73 1 -4 -10 -6 -10 -6 0 -1 9 -715 12 -3535 12 l-3533 0 -32 -155z m129 100
c10 -12 8 -33 -9 -109 -25 -105 -40 -126 -95 -126 -31 0 -36 3 -32 18 2 9 14
65 27 125 l22 107 37 0 c21 0 43 -7 50 -15z m122 -70 c-4 -118 -12 -165 -28
-165 -8 0 -14 10 -14 25 0 18 -5 25 -20 25 -13 0 -22 -9 -26 -25 -6 -23 -44
-38 -44 -16 0 9 78 220 87 234 3 4 15 7 26 5 20 -3 22 -8 19 -83z m158 69 c0
-9 -9 -18 -19 -21 -20 -5 -22 -13 -52 -155 -10 -47 -16 -58 -32 -58 -12 0 -17
5 -14 14 3 8 13 53 23 102 13 65 14 89 6 92 -7 2 -12 12 -12 23 0 16 7 19 50
19 39 0 50 -3 50 -16z m87 -66 c-4 -46 -7 -102 -7 -125 0 -36 -3 -43 -20 -43
-15 0 -20 7 -20 25 0 18 -5 25 -20 25 -13 0 -20 -7 -20 -20 0 -11 -7 -23 -15
-27 -34 -13 -31 7 28 165 27 72 33 82 55 82 l25 0 -6 -82z m218 63 c7 -24 -12
-74 -27 -69 -7 3 -13 14 -13 26 0 28 -17 30 -27 3 -5 -15 0 -29 19 -51 24 -29
25 -36 17 -78 -10 -52 -41 -76 -78 -62 -28 10 -30 73 -3 78 13 3 17 -3 17 -22
0 -29 14 -35 24 -10 11 28 6 51 -14 69 -24 22 -27 81 -4 113 20 29 80 31 89 3z
m65 9 c0 -5 -9 -48 -20 -95 -23 -97 -24 -115 -10 -115 15 0 26 33 46 133 14
72 20 87 36 87 10 0 18 -1 18 -3 0 -23 -52 -223 -61 -233 -8 -10 -26 -14 -48
-12 -33 3 -36 6 -35 33 0 17 9 72 20 122 15 75 22 93 37 93 9 0 17 -4 17 -10z
m190 -5 c13 -16 6 -81 -12 -102 -5 -7 -7 -17 -3 -22 10 -18 -15 -90 -35 -101
-10 -5 -33 -10 -50 -10 -31 0 -32 2 -28 33 6 37 37 186 43 205 6 17 70 15 85
-3z m128 -22 c2 -24 -2 -34 -17 -43 -19 -10 -21 -8 -21 19 0 23 -4 29 -15 25
-22 -9 -18 -57 5 -77 25 -21 27 -83 4 -115 -11 -15 -25 -22 -50 -22 -39 0 -50
16 -40 55 8 33 36 33 36 0 0 -33 26 -33 34 1 7 25 1 38 -31 72 -16 16 -16 42
-2 79 13 35 27 44 64 41 26 -3 30 -8 33 -35z m120 -5 c3 -33 0 -38 -18 -38
-16 0 -20 4 -15 19 7 23 -9 36 -22 18 -12 -15 -43 -159 -37 -169 10 -15 22 -8
28 17 8 32 36 33 36 2 0 -33 -32 -59 -67 -55 -36 4 -40 31 -19 135 19 90 37
115 80 111 28 -3 31 -7 34 -40z m120 26 c14 -25 -10 -114 -30 -114 -6 0 -7
-22 -3 -60 7 -56 6 -60 -14 -60 -19 0 -21 6 -21 53 -1 57 -13 49 -24 -15 -4
-24 -13 -38 -21 -38 -20 0 -19 15 6 143 l22 107 38 0 c25 0 41 -6 47 -16z m72
-6 c0 -13 -9 -60 -20 -106 -11 -46 -20 -92 -20 -103 0 -12 -7 -19 -20 -19 -24
0 -24 -2 -6 80 34 159 38 170 52 170 8 0 14 -10 14 -22z m126 10 c12 -18 5
-80 -11 -105 -8 -12 -24 -23 -36 -25 -22 -3 -29 -17 -43 -80 -7 -31 -36 -39
-36 -10 0 18 36 195 45 220 6 16 71 16 81 0z m134 8 c0 -16 -14 -36 -25 -36
-17 0 -19 -7 -40 -117 -15 -77 -21 -93 -36 -93 -23 0 -23 -10 2 105 20 92 20
97 4 109 -28 20 -8 36 46 36 27 0 49 -2 49 -4z m50 1 c0 -2 -11 -56 -25 -121
-14 -65 -25 -120 -25 -122 0 -2 -7 -4 -15 -4 -20 0 -19 12 7 141 18 90 25 109
40 109 10 0 18 -1 18 -3z m127 -14 c9 -24 -26 -194 -45 -215 -9 -11 -29 -18
-49 -18 -31 0 -33 2 -33 34 0 55 29 178 47 198 21 23 71 24 80 1z m73 -38 c0
-69 15 -72 24 -5 4 31 13 53 22 57 8 3 17 4 19 2 2 -2 -8 -59 -23 -126 -21
-103 -29 -123 -44 -123 -15 0 -18 8 -18 53 -1 57 -13 49 -24 -15 -4 -24 -13
-38 -21 -38 -20 0 -19 21 6 132 26 120 26 118 44 118 12 0 15 -12 15 -55z
m328 22 c2 -16 1 -35 -2 -43 -8 -22 -36 -4 -36 23 0 48 -27 6 -39 -60 -6 -34
-14 -72 -17 -84 -5 -17 -2 -23 9 -23 8 0 18 11 21 25 7 28 36 34 36 7 0 -34
-29 -62 -64 -62 -20 0 -36 6 -39 14 -8 21 33 199 51 219 10 12 27 17 46 15 26
-2 32 -8 34 -31z m108 -13 c-3 -26 -6 -82 -6 -125 0 -72 -2 -79 -20 -79 -15 0
-20 7 -20 25 0 18 -5 25 -20 25 -13 0 -22 -9 -26 -25 -7 -30 -34 -34 -34 -5 0
11 7 37 16 58 9 20 24 63 35 95 24 75 25 77 56 77 25 0 26 -2 19 -46z m154 26
c7 -14 6 -31 -5 -62 -8 -24 -15 -55 -15 -70 0 -33 -17 -80 -33 -90 -7 -4 -29
-8 -50 -8 -34 0 -37 2 -32 23 7 30 45 218 45 223 0 2 18 4 40 4 29 0 42 -5 50
-20z m70 13 c0 -5 -9 -48 -20 -97 -24 -110 -24 -106 0 -106 13 0 20 -7 20 -20
0 -17 -7 -20 -40 -20 -33 0 -40 3 -40 20 0 19 35 191 45 218 5 13 35 17 35 5z
m160 -13 c0 -15 -7 -20 -29 -20 -29 0 -41 -14 -41 -51 0 -12 7 -19 20 -19 31
0 24 -27 -10 -39 -16 -5 -30 -14 -30 -20 0 -5 -3 -16 -6 -24 -4 -11 2 -16 23
-19 17 -2 28 -9 28 -18 0 -11 -13 -16 -47 -18 -27 -2 -48 1 -48 6 0 5 11 59
25 120 14 61 25 113 25 116 0 3 20 6 45 6 38 0 45 -3 45 -20z m180 5 c0 -8 -8
-19 -18 -24 -13 -7 -23 -37 -37 -106 -17 -87 -33 -120 -51 -102 -5 5 13 106
32 181 5 20 2 26 -10 26 -16 0 -22 21 -9 33 3 4 26 7 50 7 31 0 43 -4 43 -15z
m44 -32 c-2 -27 -7 -63 -9 -81 -6 -51 11 -29 36 49 21 65 40 91 56 75 3 -3
-14 -60 -38 -126 -37 -101 -47 -120 -65 -120 -11 0 -23 2 -25 4 -6 7 8 224 16
236 3 5 13 10 20 10 11 0 13 -11 9 -47z m265 29 c6 -10 7 -28 4 -40 -7 -27
-44 -31 -40 -4 3 25 -16 27 -25 3 -5 -15 0 -29 17 -50 28 -32 30 -53 14 -100
-11 -32 -39 -46 -76 -39 -25 5 -26 71 0 76 13 3 17 -3 17 -23 0 -37 24 -27 28
11 2 20 -4 36 -18 49 -25 23 -27 90 -3 117 21 24 69 24 82 0z m71 11 c0 -5 -9
-48 -20 -97 -23 -105 -24 -119 -8 -114 13 5 16 16 43 141 11 51 22 77 31 77
19 0 17 -27 -6 -135 -22 -98 -35 -117 -75 -117 -48 0 -50 12 -26 129 26 121
27 123 47 123 8 0 14 -3 14 -7z m190 -8 c13 -16 6 -81 -12 -102 -5 -7 -7 -16
-4 -21 10 -16 -17 -93 -36 -103 -10 -6 -34 -9 -52 -7 l-32 3 27 120 c15 66 28
121 28 123 3 9 71 -1 81 -13z m125 -25 c0 -25 -5 -36 -17 -38 -12 -2 -18 3
-18 17 0 25 -16 36 -24 15 -11 -28 -6 -51 14 -69 24 -22 27 -81 4 -113 -11
-15 -25 -22 -50 -22 -39 0 -50 16 -40 55 8 33 36 33 36 0 0 -29 15 -33 29 -7
13 25 11 34 -15 65 -26 31 -30 66 -13 100 17 31 29 38 64 35 27 -3 30 -6 30
-38z m123 -2 c3 -33 0 -38 -17 -38 -16 0 -21 6 -21 26 0 48 -27 8 -39 -59 -7
-34 -15 -72 -18 -84 -4 -16 -1 -23 10 -23 8 0 18 11 21 25 4 14 13 25 21 25
20 0 19 -39 -2 -62 -20 -22 -78 -24 -87 -3 -8 22 33 197 52 218 10 12 27 17
46 15 28 -3 31 -7 34 -40z m120 26 c14 -25 -10 -114 -30 -114 -6 0 -7 -22 -3
-60 7 -56 6 -60 -14 -60 -18 0 -21 6 -21 45 0 25 -4 45 -9 45 -5 0 -12 -20
-15 -45 -6 -36 -11 -45 -27 -45 -15 0 -19 5 -15 18 6 19 46 217 46 226 0 3 18
6 40 6 26 0 42 -6 48 -16z m72 12 c0 -6 -38 -191 -46 -220 -7 -29 -34 -36 -34
-8 0 18 36 195 45 220 4 11 35 18 35 8z m118 -8 c15 -15 15 -46 1 -86 -7 -22
-20 -34 -44 -42 -29 -10 -33 -15 -39 -60 -5 -41 -10 -50 -26 -50 -11 0 -20 2
-20 4 0 3 11 56 25 120 14 64 25 118 25 121 0 10 66 5 78 -7z m132 -7 c0 -10
-8 -21 -19 -24 -15 -3 -22 -24 -37 -102 -16 -89 -32 -120 -51 -101 -5 5 17
133 33 189 4 11 0 17 -10 17 -9 0 -16 9 -16 20 0 17 7 20 50 20 42 0 50 -3 50
-19z m56 -3 c-3 -13 -16 -69 -28 -125 -18 -88 -24 -103 -41 -103 -12 0 -17 5
-14 13 3 7 15 63 28 125 20 96 26 112 42 112 15 0 18 -5 13 -22z m131 6 c8
-22 -25 -184 -43 -212 -12 -18 -23 -23 -52 -20 -34 3 -37 5 -36 33 2 53 36
185 51 200 20 20 72 19 80 -1z m74 -46 l1 -63 15 63 c10 40 20 62 29 62 18 0
17 -14 -9 -140 -17 -80 -27 -106 -40 -108 -14 -3 -17 4 -17 42 0 60 -16 67
-25 10 -5 -39 -26 -66 -40 -53 -2 2 2 33 10 69 7 36 18 91 25 123 9 46 15 57
31 57 17 0 19 -8 20 -62z m305 -30 c-3 -50 -6 -106 -6 -125 0 -26 -4 -33 -20
-33 -15 0 -20 7 -20 25 0 18 -5 25 -20 25 -13 0 -20 -7 -20 -20 0 -20 -25 -38
-37 -27 -3 4 13 61 37 127 39 110 44 120 68 120 l25 0 -7 -92z m104 88 c0 -6
-38 -191 -46 -220 -7 -30 -34 -35 -34 -7 0 17 35 186 45 219 4 10 35 18 35 8z
m119 -5 c18 -12 9 -86 -14 -111 -14 -16 -16 -30 -10 -75 6 -51 5 -55 -14 -55
-18 0 -21 6 -21 45 0 25 -4 45 -9 45 -6 0 -13 -20 -17 -45 -5 -35 -10 -45 -25
-45 -15 0 -18 5 -15 23 17 76 46 217 46 222 0 9 64 5 79 -4z m131 -10 c0 -10
-8 -21 -19 -24 -15 -3 -22 -24 -37 -102 -16 -88 -32 -120 -51 -101 -3 3 3 49
15 103 19 88 19 98 5 108 -25 19 -6 35 43 35 36 0 44 -3 44 -19z m60 15 c0 -3
-6 -33 -14 -68 -8 -35 -20 -89 -26 -120 -9 -48 -15 -58 -32 -58 -12 0 -18 5
-15 13 3 7 15 63 28 125 19 93 26 112 41 112 10 0 18 -2 18 -4z m80 -81 l5
-80 38 83 c22 49 43 82 52 82 19 0 19 -3 -7 -133 -13 -65 -27 -111 -35 -114
-21 -8 -24 0 -14 50 4 26 7 47 5 47 -2 0 -13 -22 -25 -50 -29 -67 -49 -66 -50
3 l-1 52 -12 -50 c-8 -33 -18 -51 -29 -53 -9 -2 -17 -2 -17 0 0 9 31 164 41
203 8 34 16 45 28 43 13 -3 17 -20 21 -83z m238 68 c-2 -12 -14 -19 -36 -21
-17 -2 -32 -9 -32 -15 0 -7 -3 -22 -6 -34 -5 -19 -2 -23 16 -23 15 0 20 -5 18
-17 -2 -10 -11 -17 -20 -15 -19 3 -41 -31 -36 -56 2 -9 12 -17 23 -17 11 0 21
-8 23 -17 3 -15 -4 -18 -42 -18 -55 0 -54 -5 -24 143 l23 107 48 0 c41 0 48
-3 45 -17z m109 -70 l-6 -88 30 88 c19 54 36 87 45 87 22 0 17 -26 -25 -140
-35 -96 -41 -105 -66 -108 l-27 -4 6 99 c9 129 14 153 33 153 14 0 15 -12 10
-87z m203 72 c0 -8 -9 -19 -20 -25 -11 -6 -20 -18 -20 -28 0 -41 -35 -175 -47
-179 -8 -3 -16 -3 -19 -1 -4 5 16 119 31 181 5 20 4 27 -8 27 -16 0 -30 24
-20 34 4 3 28 6 55 6 35 0 48 -4 48 -15z m52 10 c3 -3 -3 -40 -13 -83 -20 -86
-24 -132 -10 -132 12 0 16 14 39 125 14 66 23 91 35 93 22 4 21 -4 -3 -117
-28 -125 -32 -133 -78 -129 -37 3 -37 3 -36 48 1 106 40 220 66 195z m242 -97
c-3 -57 -7 -113 -10 -125 -6 -32 -34 -30 -34 2 0 18 -5 25 -19 25 -12 0 -21
-9 -25 -25 -7 -26 -36 -35 -36 -11 0 12 39 129 70 212 7 16 17 24 34 24 l25 0
-5 -102z m106 42 l5 -55 13 58 c9 36 19 57 28 57 18 0 17 -5 -1 -93 -30 -140
-36 -157 -56 -157 -16 0 -19 8 -20 53 l-1 52 -12 -50 c-8 -33 -18 -51 -29 -53
-20 -4 -21 4 -8 61 41 181 43 188 60 185 12 -2 17 -18 21 -58z m179 51 c12 -8
13 -22 1 -95 -7 -47 -21 -100 -31 -116 -16 -25 -25 -30 -59 -30 -22 0 -40 5
-40 10 0 11 38 194 46 223 6 19 58 24 83 8z m155 -13 c3 -13 6 -50 6 -83 l1
-60 39 83 c24 51 45 82 54 82 20 0 20 1 -4 -111 -27 -129 -30 -139 -47 -139
-11 0 -13 10 -8 48 l6 47 -22 -47 c-30 -67 -49 -63 -50 10 -1 59 -4 55 -23
-30 -7 -29 -36 -39 -36 -13 0 14 37 197 45 223 7 20 32 14 39 -10z m235 5 c6
-12 3 -46 -9 -103 -26 -116 -34 -130 -82 -130 -37 0 -38 1 -38 36 0 52 30 176
48 197 8 9 28 17 43 17 17 0 32 -7 38 -17z m120 -1 c13 -24 -3 -93 -24 -108
-12 -9 -14 -23 -9 -68 6 -53 5 -56 -15 -56 -18 0 -21 6 -21 46 0 54 -15 51
-24 -6 -4 -26 -11 -40 -21 -40 -20 0 -19 6 7 138 l23 112 37 0 c26 0 40 -6 47
-18z m129 1 c-2 -10 -14 -19 -28 -21 -31 -4 -36 -10 -44 -44 -6 -25 -4 -28 19
-28 36 0 32 -29 -5 -36 -16 -4 -30 -12 -30 -19 0 -8 -3 -20 -6 -29 -5 -12 0
-16 20 -16 19 0 26 -5 26 -20 0 -17 -7 -20 -45 -20 -33 0 -45 4 -45 15 0 14
37 197 45 223 3 7 22 12 50 12 39 0 46 -3 43 -17z m22 -213 c0 -13 -7 -20 -20
-20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m80 0 c0 -13 -7
-20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z"/>
<path d="M1986 1521 c-20 -86 -20 -101 -2 -101 9 0 18 21 25 63 6 34 14 72 17
85 5 16 2 22 -9 22 -11 0 -20 -21 -31 -69z"/>
<path d="M2130 1511 c-12 -24 -13 -41 -1 -41 5 0 11 14 13 30 5 35 3 37 -12
11z"/>
<path d="M2371 1509 c-14 -40 -14 -39 4 -39 10 0 15 11 15 35 0 43 -5 44 -19
4z"/>
<path d="M2840 1564 c-13 -34 -13 -44 4 -44 15 0 26 21 26 51 0 27 -19 23 -30
-7z"/>
<path d="M2820 1464 c-6 -15 -8 -35 -5 -43 6 -14 8 -13 21 3 16 23 18 52 4 61
-6 3 -15 -6 -20 -21z"/>
<path d="M3205 1560 c-7 -33 -1 -50 14 -34 14 14 17 57 4 61 -6 2 -14 -10 -18
-27z"/>
<path d="M3406 1558 c-11 -54 16 -50 29 5 5 20 3 27 -8 27 -8 0 -17 -13 -21
-32z"/>
<path d="M3723 1588 c-5 -7 -15 -40 -22 -73 -7 -33 -15 -70 -18 -82 -4 -16 -1
-23 10 -23 16 0 31 49 43 138 7 53 4 62 -13 40z"/>
<path d="M4232 1513 c-12 -39 -7 -57 8 -33 9 14 14 70 7 70 -2 0 -9 -17 -15
-37z"/>
<path d="M4375 1559 c-12 -54 17 -51 30 4 6 20 4 27 -8 27 -9 0 -18 -13 -22
-31z"/>
<path d="M4357 1473 c-13 -13 -7 -53 8 -53 10 0 15 10 15 30 0 29 -9 38 -23
23z"/>
<path d="M5360 1578 c0 -7 -3 -23 -6 -35 -7 -27 20 -33 30 -7 11 28 6 54 -9
54 -8 0 -15 -6 -15 -12z"/>
<path d="M5336 1458 c-9 -34 -8 -38 9 -38 8 0 15 3 15 8 0 4 3 17 6 30 5 16 2
22 -9 22 -8 0 -17 -10 -21 -22z"/>
<path d="M5725 1559 c-7 -32 0 -49 14 -33 5 5 11 21 15 37 5 20 3 27 -8 27 -8
0 -17 -14 -21 -31z"/>
<path d="M5926 1574 c-9 -23 -7 -54 3 -54 11 0 21 25 21 51 0 23 -15 25 -24 3z"/>
<path d="M6242 1588 c-13 -16 -45 -169 -37 -177 13 -13 33 32 45 104 13 77 11
96 -8 73z"/>
<path d="M6627 1523 c-2 -10 -8 -26 -12 -35 -5 -13 -2 -18 9 -18 12 0 16 10
16 35 0 38 -5 45 -13 18z"/>
<path d="M6846 1574 c-9 -23 -7 -54 3 -54 11 0 32 56 24 64 -11 10 -21 7 -27
-10z"/>
<path d="M7962 1511 c-13 -36 -9 -52 9 -34 6 6 9 23 7 39 l-3 29 -13 -34z"/>
<path d="M8246 1582 c-2 -4 -9 -36 -16 -70 -6 -35 -14 -70 -17 -78 -3 -8 1
-14 11 -14 16 0 31 43 42 124 5 30 3 46 -4 46 -6 0 -13 -3 -16 -8z"/>
<path d="M8635 1590 c-11 -17 -34 -121 -35 -152 0 -16 4 -28 9 -28 13 0 52
173 42 183 -5 5 -12 4 -16 -3z"/>
<path d="M8746 1558 c-11 -54 16 -50 29 5 5 20 3 27 -8 27 -8 0 -17 -13 -21
-32z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
